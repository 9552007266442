@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Josefin+Slab&family=Tilt+Neon&display=swap');

body {
  background-color: rgb(247, 247, 247);
}

.nav {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.nav > h1 {
  font-family: 'Josefin Slab', cursive; 
  padding: 8px;
}

.quote {
  font-family: 'Josefin Slab', cursive; 
  align-items: center;
  justify-content: center;
}

.header {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  
  height: 10rem;
}

.header--subcategories {
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0%;

  z-index: 5;

  font-size: larger;
  font-family: 'Josefin Slab', cursive;
}

.link {
  display: flex;
  align-items: center;
  padding: 16px 32px 16px;
}

.icon {
  padding: 4px;
}

.header--title {

  grid-row: 2 / 3;
  display: flex;
  justify-content: center;
  z-index: 2;
  

	text-shadow:
		-2px -2px 0 rgb(255, 255, 255),
		2px -2px 0 rgb(255, 255, 255),
		-2px 2px 0 rgb(255, 255, 255),
		2px 2px 0 rgb(255, 255, 255);
  position: relative;
  
  font-size: 5em;
  top: 8vh;

  font-family: 'Dancing Script', cursive;
}


.hero {
  display: grid;
  justify-content: center;
  align-items: center;
}

.Body {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1em;
}

 .Body > img {
  position: relative;
 }

 .Body > div {
  position: absolute;
  padding: 2em;
  max-width: 60%;
  line-height: 1.75;
  font-family: 'Tilt Neon', cursive;
  color: bisque;
  background-color: rgb(34, 33, 33);

 }

 .cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
}

.card {
  width: 175px;
  font-size: 12px;
  flex: 0 0 auto;        
  display: flex;  
  margin: 3em;        
  flex-direction: column; 
  position: relative;
  font-family: 'Tilt Neon', cursive;

}

.card--image {
  width: 100%;
  border-radius: 9px;
  margin-bottom: 9px;
}

.card--title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card--price {
  margin-top: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 12px;
  font-family: 'Tilt Neon', cursive;

  background-color: rgba(50, 56, 61, 0.192);
}

.footer > div {
  padding-left: 8px;
}

.footer > img {
  max-width: 5%;
  height: auto;
}
